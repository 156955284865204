import React, { Component } from 'react';
import logo from './resources/logo.svg';
import linkedin from './resources/iconmonstr-linkedin-3.svg'
import './App.css';
import Dropzone from 'Components/Hooks/Dropzone';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faUpload);
library.add(faFilePowerpoint);
library.add(faTrash);

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            React App
          </p>

         
        </header>
        
      </div>
    );
  }
}

export default App;
